<template>
  <div class="accountInfo">
    <div class="regular">
      <div class="group group-no-padded">
        <div class="group-body">
          <p class="dcB1oZR_">隐私权政策</p>
          <ul class="list">
             <div v-for="item in ListData" @click="info(item.protocolNo)">
               <template v-if="item.protocolType =='B'">
                <li  class="item item-linked">
                    <a href="javascript:;">
                      <h3 class="item-title">{{item.protocolName}}</h3>
                      <span class="el-icon-arrow-right"></span>
                    </a>
                </li>
              </template>
            </div>
          </ul>
          <p class="dcB1oZR_">用户服务协议</p>
          <ul class="list">
            <div v-for="item in ListData" @click="info(item.protocolNo)">
               <template v-if="item.protocolType =='A'">
                <li  class="item item-linked">
                    <a href="javascript:;">
                      <h3 class="item-title">{{item.protocolName}}</h3>
                      <span class="el-icon-arrow-right"></span>
                    </a>
                </li>
              </template>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { procotolist} from "../services/api";
export default {
  data() {
    return {
      ListData:{ },
    };
  },
  created: function() {
    this.procotolist();
  },
  methods: {
    info(protocolNo){
      this.$router.push({
        path: '/privacySettingsDetail',
        query: {
          protocolNo:protocolNo,
        }
      });
    },
    async procotolist() {
      const datas = await procotolist({protocolFunction:'A'});
      if (datas.code == 1) {
         this.ListData=datas.data.result;
      }
    },
   tips(type){
       switch(type) {
            case 1:
                this.$toast('已成立本金之和+已分配本金之和');
                break;
            case 2:
                this.$toast('已起息，未分配的本金之和');
                break;
            default:
                this.$toast('所有已分配的本息之和 ');
        }
       
    },
    
  }
};
</script>
<style lang="scss" scoped>
.accountInfo{
  margin-top: 2.25rem;    margin-bottom: 2.25rem;
  .regular {
    position: relative;
    top:0;  
    .group {
      margin: 1rem 0; 
      .dcB1oZR_ {
          padding-left: 1rem;
          height: 2.5rem;
          line-height: 2.5rem;
          font-size: .9rem;
      }
      ul{
        h3{font-weight: normal;}
        .item {
              background: #fff;
              padding: 1rem;
              margin-bottom: -1px;
              border: 1px solid #e6e6e6;
              border-width: 1px 0;
              min-height: 1rem;
              a{ 
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                align-items: center;
                width: 100%;
                color:#999;
              }
              .item-linked>a {
                  width: 100%;
                  padding: 0.625rem 0.46875rem 0.625rem 0.9375rem;
                  color: #222;
              }
          }
        
      }
    }
  }
}
</style>
